import styles from './index.module.scss'
import { useContext, useState, useMemo, useEffect } from 'react'
import { Modal, Segmented, message, ConfigProvider } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { CLOTH_TYPE, IMAGE_TYPE, UPLOAD_SOURCE } from "@utils/CONST";

const SegmentedCom = (props) => {
    const navigate = useNavigate();

    const [segmentedVal, setSegmentedVal] = useState(CLOTH_TYPE.SUIT)

    useEffect(() => {
        props.clothTypeChange(CLOTH_TYPE.SUIT)
    }, [])


    const segmentedChange = (value) => {
        setSegmentedVal(value)
        props.clothTypeChange(value)
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Segmented: {
                        trackBg: 'rgba(227, 227, 246, 1)',
                    },
                },
            }}
        >
            <div className={styles.segmented} style={{ width: '220px', margin: '0 auto', marginLeft: '-50%'}}>
                <Segmented
                    options={[
                        { label: '组合套装', value: CLOTH_TYPE.SUIT },
                        { label: '连体装', value: CLOTH_TYPE.SKIRT },
                    ]}
                    block
                    value={segmentedVal}
                    onChange={segmentedChange}
                />
            </div>
        </ConfigProvider>
    )
}

export default SegmentedCom