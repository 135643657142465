import styles from './index.module.scss'
import { Upload, Button, Divider, Input, message, Carousel, Select, Checkbox, Spin } from 'antd';
import React, {useState, useEffect, useRef, useMemo, useContext, useCallback} from 'react';
import API from '@api/api'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import Utils from '@utils/utils'
import Matting from '../components/Matting'
import { IMAGE_TYPE } from "@utils/CONST"
import StrengthSlider from '../components/StrengthSlider.jsx';
import StrengthStepSlider from '../components/StrengthStepSlider.jsx'
import PSAIModal from '@PSAIComponents/PSAIModal';
import { AppContext } from '@utils/AppContext';
import VersionList from '../components/VersionList';
import * as _ from "underscore";
import axios from 'axios'
import eventBus from '@utils/eventBus';
import { PRIVATE_ABILITIES } from "@utils/CONST"

const { TextArea } = Input;

export default function CustomSceneForModel (props) {
    const {
        isProEnv,
        hasPrivateAbility
    } = useContext(AppContext)
    const initedRef = useRef(false) // 初始化时不应该更改描述场景，避免覆盖掉用户自己填写的场景
    const maxLimit = useRef(2048)
    const minLimit = useRef(500)

    const [positivePrompt, setPositivePrompt] = useState(props.positivePrompt)
    const [refImg, setRefImg] = useState(props.bkgUrl)
    const [version, setVersion] = useState((!props.refVersion && props.refVersion != 0) ? 1 : props.refVersion)
    const [strength, setStrength] = useState((!props.refStrength && props.refStrength != 0) ? 0.5 : props.refStrength)
    
    const [loading, setLoading] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [isMattiongOpen, setIsMattiongOpen] = useState(false)
    const [cropParams, setCropParams] = useState({
        height: '',
        width: '',
        src: ''
    })
    const [confirmLoading, setConfirmLoading] = useState(false);

    const customListRef = useRef([])
    const [customList, setCustomList] = useState([])
    const getCustomModelBackgroundList = () => {
        return API.modelBackgroundList({
            custom: 1
        }).then(res => {
            if (res.code == 0) {
                customListRef.current = res.data
                setCustomList(res.data)
            } else {
                customListRef.current = []
                setCustomList([])
                message.warning(res.message)
            }
        })
    }
    useEffect(() => {
        getCustomModelBackgroundList().then(() => {
            if (refImg) {
                const item = customListRef.current.find(item => item.imageUrl == refImg)
                if (item) {
                    savePrompt(item.id, positivePrompt).then(res => {
                        initedRef.current = true
                    })
                } else {
                    setRefImg("")
                    initedRef.current = true
                }
            } else {
                initedRef.current = true
            }
        })
    }, [])

    const showarr1 = useMemo(() => {
        if(customList.length == 0) return []
        return customList.filter(item => item.attr.version == 1 || !item.attr.version)
    }, [customList])
    const showarr3 = useMemo(() => {
        if(customList.length == 0) return []
        return customList.filter(item => item.attr.version == 3)
    }, [customList])
    const showList = useMemo(() => {
        if (version == 1) {
            return Utils.chunkArray([...showarr1], 9)
        } else if (version == 3) {
            return Utils.chunkArray([...showarr3], 9)
        }
    }, [showarr3, showarr3, version])

    const refImgId = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        return item?.id
    }, [refImg, customList])
    const refImgLocalInfo = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        if (item) {
            return item.attr.objectLocationInfo || null
        } else {
            return null
        }
    }, [refImg, customList])
    const refImgSize = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        if (item) {
            return {
                width: item.attr.width,
                height: item.attr.height
            }
        } else {
            return null
        }
    }, [refImg, customList])
    const refImgMask = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        if (item) {
            return item.attr.mask || null
        } else return null
    }, [refImg, customList])
    const refImgErased = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        if (item) {
            return item.attr.imgErased || null
        } else return null
    }, [refImg, customList])
    const refImgProcessed = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        if (item) {
            return item.attr.imgProcessed || null
        } else return null
    }, [refImg, customList])

    useEffect(() => {
        return () => {
            props.onChange({
                definedFlag: 0,
                positivePrompt: '',
                refImg: '',
                refImgMask: null,
                refImgErased: "",
                refImgProcessed: "",
                refImgSize: null,
                refImgLocalInfo: null,
                refVersion: 1,
                refStrength: .5,
            })
        }
    }, [])
    useEffect(() => {
        props.onChange({
            definedFlag: 1,
            positivePrompt: positivePrompt,
            refImg: refImg,
            refImgMask: refImgMask,
            refImgErased: refImgErased,
            refImgProcessed: refImgProcessed,
            refImgSize: refImgSize,
            refImgLocalInfo: refImgLocalInfo,
            refVersion: version,
            refStrength: strength,
        })
    }, [positivePrompt, refImg, refImgMask, refImgErased, refImgProcessed, refImgSize, refImgLocalInfo, version, strength])

    const getFileWidthHeight = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = function(e) {
                const img = new window.Image()
                img.onload = function() {
                    const width = img.width
                    const height = img.height
                    resolve({width, height})
                }
                img.src = e.target.result
            }
            reader.readAsDataURL(file)
        })
    }
    const uploadFile = (file) => {
        return Utils.checkImageIslegal(file, 1).then(res => {
            if (!res) return false
            if (!res.isLegal) {
                PSAIModal.confirm({
                    content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                    okText: '重新上传',
                    cancelText: '取消',
                    onOk() {
                        Utils.openFile().then((res) => {
                            if(res) beforeUpload(res)
                        })
                    },
                    onCancel() {},
                })
                return false
            } else {
                const {imageAntispamId, data} = res
                return {
                    url: data,
                    imageAntispamId
                }
            }
        }).catch((err) => {
            message.error(err.message)
        })
    }
    const beforeUpload = async (file) => {
        setLoading(true)
        try {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'|| file.type === 'image/avif';
            if (!isJpgOrPng) throw new Error('您只能上传JPG/PNG/WEBP/AVIF格式的文件')
            const resizeRes = await Utils.resizeImageFileMaxByAliyunBy(file, maxLimit.current)
            if (!resizeRes || !resizeRes.success) throw new Error(resizeRes?.message)
            const {width, height} = await getFileWidthHeight(file)

            if (version == 3) {
                if (Math.min(width, height) < minLimit.current) {
                    throw new Error('请上传尺寸在500x500以上的参考图片')
                }
                setCropParams({
                    src: resizeRes.base64,
                    width: resizeRes.width,
                    height: resizeRes.height
                })
                setIsMattiongOpen(true)
            } else {
                if (Math.min(width, height) < minLimit.current) {
                    throw new Error('请上传尺寸在500x500以上的参考图片')
                }
                const res = await uploadFile(file)
                if (!res) return
                const {url, imageAntispamId} = res
                const res2 = await API.saveCustomMaterial({
                    func: IMAGE_TYPE.MODEL,
                    type: "background",
                    image: url,
                    imageAntispamId: imageAntispamId,
                    attr: {
                        width: width,
                        height: height,
                        version: version,
                    }
                })
                if(res2.code != 0) throw new Error(res2.message)
                message.success('添加成功')
                const imageUrl = res2.data.imageUrl
                getCustomModelBackgroundList().then(() => setRefImg(imageUrl))
            }
        } catch (err) {
            message.warning(err.message)
        } finally {
            setLoading(false)
            return false
        }
    }

    const [textLoading, setTextLoading] = useState(false)
    const image2Text = (image_path) => {
        setTextLoading(true)
        return API.image2Text({
            image_path: image_path
        }).then(res => {
            if(res.code == 0) {
                return res.data
            } else {
                message.error(res.message)
            }
        }).finally(() => {
            setTextLoading(false)
        })
    }

    const handleSelect = (item) => {
        if (refImg == item.imageUrl) {
            setRefImg("")
        } else {
            setRefImg(item.imageUrl)
        }
    }

    //
    useEffect(() => {
        if (!initedRef.current) return
        if (!refImgId) setPositivePrompt()
        else {
            const item = customListRef.current.find(item => item.id == refImgId)
            if (item.attr.prompt) {
                setPositivePrompt(item.attr.prompt)
            } else {
                const url = item.imageUrl
                image2Text(url).then(text => {
                    setPositivePrompt(text)
                    savePromptDebounce(refImgId, text)
                })
            }
        }
    }, [refImgId])

    const savePromptDebounce = useCallback(_.debounce((id, prompt) => {
        return API.savePrompt({
            id,
            prompt
        }).then(() => {
            return getCustomModelBackgroundList()
        }).catch(err => {
            message.warning(err.message)
        })
    }, 400), [])
    const savePrompt = (id, prompt) => {
        return API.savePrompt({
            id,
            prompt
        }).then(() => {
            return getCustomModelBackgroundList()
        }).catch(err => {
            message.warning(err.message)
        })
    }

    const handelDelete = (item, e) => {
        e.stopPropagation()
        PSAIModal.confirm({
            title: "删除参考图",
            content: "请确认是否删除此参考图？",
            onOk() {
                return API.removeCustomMaterial({
                    id: item.id
                }).then(res => {
                    if(res.code == 0) {
                        message.success('删除成功')
                        getCustomModelBackgroundList().then(() => {
                            if (refImg == item.imageUrl) setRefImg("")
                        })
                    } else {
                        message.error(res.message)
                    }
                })
            },
            onCancel() {},
        })
    }

    const isMounted = useRef()
    useEffect(() => {
        isMounted.current = true
        return () => isMounted.current = false
    }, [])
    const airemovePredictionResult = (id)  => {
        return new Promise((resolve, reject) => {
            const loopFunc = () => {
                axios.post(API.airemovePredictionUrl + id,  {}, {
                    headers: {
                        'Content-type' : 'application/json',
                        'Ps-Auth-Token': localStorage.getItem('token')
                    }
                }).then(res => {
                    if((res.data.code == 0 && res.data.data.status == 'succeeded')) {
                        resolve(res.data.data.output[0])
                    } else if(res.data.code == 0 && res.data.data.status == 'failed') {
                        const error = Utils.parseErrorCode(`[${res.data.data.errcode}]`)
                        eventBus.emit('notification', '检测失败', error.message, 'error', error.code)
                        reject()
                    } else {
                        if (!isMounted.current) return reject()
                        setTimeout(() => {
                            loopFunc()
                        }, 400)
                    }
                })
            }
            loopFunc()
        })
    }
    const aiRemove = (img, mask) => {
        return API.airemovePrediction({
            is_hand: 0, // parseInt(ishand), // 0表示擦除， 1表示修复 // 231107 与张闯对接口参数，写死0
            init_image: '', // inputImage?inputImage: orignPic, // 修复的时候需要原图 // 231107 与张闯对接口参数，写死空字符串
            pend_image: img,
            mask_image: mask,
            prompts: 'photograph of a beautiful empty scene, highest quality settings'
        }).then(res => {
            if (res.code == 0) {
                return airemovePredictionResult(res.data).catch(err => {
                    console.error(err.message)
                })
            } else {
                return Promise.reject(res.message)
            }
        })
    }

    const mattingRef = useRef()
    const mattingOk = async () => {
        setConfirmLoading(true)
        try {
            const mattingResult = await mattingRef.current.getMattingResult()
            if (!mattingResult) throw new Error('图片处理中，请稍后')
            const {refImg, refImgFile, refMask, refImgCropped, width, height, locationInfo} = mattingResult
            const res = await uploadFile(refImgFile)
            if (res) {
                const {url, imageAntispamId} = res
                // 拍同款需要先做擦除
                const imgErased = version == 3 ? await aiRemove(url, refMask) : ""
                if (!isMounted.current) return false
                if (version == 3 && !imgErased) return false
                const res2 = await API.saveCustomMaterial({
                    func: IMAGE_TYPE.MODEL,
                    type: "background",
                    image: url,
                    imageAntispamId: imageAntispamId,
                    attr: {
                        width: width,
                        height: height,
                        version: version,
                        mask: refMask,
                        imgProcessed: refImgCropped,
                        imgErased: imgErased, // 擦除后图片
                        objectLocationInfo: locationInfo
                    }
                })
                if (res2.code != 0) throw new Error(res2.message)
                message.success('添加成功')
                const imageUrl = res2.data.imageUrl
                getCustomModelBackgroundList().then(() => setRefImg(imageUrl))
    
                setIsMattiongOpen(false)
            } else {
                setIsMattiongOpen(false)
            }
        } catch (err) {
            message.warning(err.message)
        } finally {
            setConfirmLoading(false)
        }
    }

    const onVersionChange = (version) => {
        const strength = version == 1 ? 0.5 :
                         version == 2 ? 0.2 :
                         version == 3 ? 0.6 : 0
        setVersion(version)
        setStrength(strength)
        setRefImg("")
        setPositivePrompt()
    }

    const handlePromptChange = (e) => {
        setPositivePrompt(e.target.value)
        if (refImgId) savePromptDebounce(refImgId, e.target.value)
    }

    const stepStrength = useMemo(() => {
        if (strength == 0) return 0.2
        else if (strength == 0.2) return 0.4
        else if (strength == 0.5) return 0.6
        else if (strength == 0.8) return 0.8
        else return 0.2
    }, [strength])
    const onStepChange = (strength) => {
        if (strength == 0.2) setStrength(0)
        else if (strength == 0.4) setStrength(0.2)
        else if (strength == 0.6) setStrength(0.5)
        else if (strength == 0.8) setStrength(0.8)
        else if (strength == 0) setStrength(0)
        else if (strength == 1) setStrength(0.8)
    }
    const onStepFormatter = (value) => {
        if (value == 0.2) return 0
        else if (value == 0.4) return 0.2
        else if (value == 0.6) return 0.5
        else if (value == 0.8) return 0.8
    }

    const showSameStyle = useMemo(() => {
        if (props.sceneType != IMAGE_TYPE.MODEL) return false
        return !isProEnv || hasPrivateAbility(PRIVATE_ABILITIES.MODEL_SAME_STYLE)
    }, [hasPrivateAbility, props.sceneType])
    const versionOptions = useMemo(() => {
        const array = [{
            name: '相似风格',
            value: 1,
            tipImg: props.sceneType == 'model'? require('@assets/images/guide_model_custom_scene.png'): require('@assets/images/guide_dressform_custom_scene.png'),
            placement: "rightTop",
            imgStyle: {
                width: 288,
                height: 478,
                marginTop: -32,
            }
        }, {
            name: '场景裂变',
            value: 2,
            tipImg: props.sceneType == 'model'? require('@assets/images/guide_model_scene_fragment.png'): require('@assets/images/guide_dressform_scene_fragment.png'),
            placement: "rightTop",
            imgStyle: {   
                width: 194+35,
                height: 144,
                marginTop: -32,
            }
        }]
        if (showSameStyle) array.push({
            name: '拍同款',
            value: 3,
        })
        return array
    }, [showSameStyle])

    return (
        <div className={styles.Container} >
            <VersionList version={version} options={versionOptions} onChange={onVersionChange} />
            {
                version == 1 &&
                <>
                <div className={styles.Prompt}>
                    <p className={styles.PromptTitle}>描述场景</p>
                    <Spin spinning={textLoading}>
                    <TextArea
                        className={styles.PromptInput}
                        rows={4}
                        placeholder="请在此描述您期望的展示场景，例如：高级感展台，黑色背景，光影感，质感"
                        value={positivePrompt}
                        variant="borderless"
                        onChange={handlePromptChange}
                    />
                    </Spin>
                </div>
                <p className={styles.MaterialTtile}>上传参考图</p>
                <div className={styles.MaterialTools}>
                    <Upload
                        accept="image/jpeg,image/png,image/webp"
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        className={styles.MaterialUploader}
                    >
                        <div className={styles.MaterialUploaderBox}>
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                        </div>
                    </Upload>
                    {
                        <div className={styles.MaterialStrength}>
                            {/* <p style={{marginBottom: '18px'}}>请上传不含商品的背景图</p> */}
                            <StrengthSlider
                                leftTips="更加自由"
                                rightTips="非常相似"
                                disabled={!refImg}
                                title="参考图相似度"
                                value={strength}
                                onChange={setStrength}
                                showTooltip={!isProEnv}
                                step={0.1}
                            />
                        </div>
                    }
                </div>
                <div className={styles.MaterialList}>
                    <Carousel dots={showList.length > 1 ? true: false}>
                    {
                        showList.map((item, index) => (
                            <div key={index}>
                                <div className={styles.MaterialGroup}>
                                {
                                    item.map(item2 => (
                                        <div
                                            key={item2.id}
                                            className={`${styles.materialBox} ${refImg == item2.imageUrl && styles.selectedImg}`}
                                            onClick={() => handleSelect(item2)}
                                        >
                                            <img src={Utils.getImgUrlWithWebp(item2.imageUrl, true)} />
                                            <i className={styles.del_btn} onClick={(e) => handelDelete(item2, e)}></i>
                                        </div>
                                    ))
                                }
                                </div>
                            </div>
                        ))
                    }
                    </Carousel>
                </div>
                </>
            }
            {
                version == 2 &&
                <>
                <p className={styles.PromptTitle}>原图场景</p>
                <div style={{padding: '0 4px'}}>
                    <StrengthStepSlider
                        leftTips="更加自由"
                        rightTips="非常相似"
                        value={stepStrength}
                        onChange={onStepChange}
                        showTooltip={!isProEnv}
                        formatter={onStepFormatter}
                        step={null}
                        min={0.2}
                        max={0.8}
                        marks={{
                            0.2: <i/>, // 对应0
                            0.4: <i/>, // 对应0.2
                            0.6: <i/>, // 对应0.5
                            0.8: <i/> // 对应0.8
                        }}
                    />
                </div>
                </>
            }
            {
                version == 3 &&
                <>
                <div className={styles.MaterialTools}>
                    <Upload
                        accept="image/jpeg,image/png,image/webp"
                        name="avatar"
                        listType="picture-card"
                        showUploadList={false}
                        beforeUpload={beforeUpload}
                        className={styles.MaterialUploader}
                    >
                        <div className={styles.MaterialUploaderBox}>
                            {loading ? <LoadingOutlined /> : <PlusOutlined />}
                        </div>
                    </Upload>
                    <div className={styles.MaterialStrength}>
                        {version == 1 && <p style={{marginBottom: '15px'}}>请上传不含商品的背景图</p>}
                        <StrengthSlider
                            leftTips="更加自由"
                            rightTips="非常相似"
                            disabled={!refImg}
                            title="参考图相似度"
                            max={0.9}
                            reverse={true}
                            value={strength}
                            onChange={setStrength}
                            showTooltip={!isProEnv}
                            step={0.1}
                        />
                    </div>
                </div>
                <div className={styles.MaterialList}>
                    <Carousel dots={showList.length > 1 ? true: false}>
                    {
                        showList.map((item, index) => (
                            <div key={index}>
                                <div className={styles.MaterialGroup}>
                                {
                                    item.map(item2 => (
                                        <div
                                            key={item2.id}
                                            className={`${styles.materialBox} ${refImg == item2.imageUrl && styles.selectedImg}`}
                                            onClick={() => handleSelect(item2)}
                                        >
                                            <img src={Utils.getImgUrlWithWebp(item2.imageUrl, true)} />
                                            <i className={styles.del_btn} onClick={(e) => handelDelete(item2, e)}></i>
                                        </div>
                                    ))
                                }
                                </div>
                            </div>
                        ))
                    }
                    </Carousel>
                </div>
                </>
            }
            <PSAIModal
                title='上传参考图'
                open={isMattiongOpen}
                maskClosable={false}
                closable={false}
                maxWidth={548}
                onCancel={() => setIsMattiongOpen(false)}
                confirmLoading={confirmLoading}
                onOk={mattingOk}
            >
                <div style={{padding: '20px'}}>
                    <Matting ref={mattingRef} cropParams={cropParams} />
                </div>
            </PSAIModal>
        </div>
    )
}