import React, { useContext, useState, useEffect, useMemo } from 'react';
import { useNavigate, useLocation }from "react-router-dom"; 
import { AppContext } from '@utils/AppContext';
import SidebarTasks from '../sidebarTasks/sidebarTasks';
import { Button, Popover, Tooltip } from 'antd';
import Utils from '@utils/utils'
import { HOME_PAGE_TYPE, IMAGE_TYPE } from "@utils/CONST"
import styles from './sidebar.module.scss'

const MENU_LIST = [{
    name: 'AI模特',
    key: IMAGE_TYPE.MODEL,
    icon: require('@assets/images/icon_model.png'),
    icon_active: require('@assets/images/icon_model_actived.png')
}, 
// {
//     name: '人台图',
//     key: IMAGE_TYPE.DRESSFORM,
//     icon: require('@assets/images/icon_dressform.png'),
//     icon_active: require('@assets/images/icon_dressform_actived.png')
// }, 
{
    name: 'AI试衣',
    key: IMAGE_TYPE.CLOTHES,
    icon: require('@assets/images/icon_clothes.png'),
    icon_active: require('@assets/images/icon_clothes_actived.png')
}, {
    name: '商品图',
    key: IMAGE_TYPE.COMMODITY,
    icon: require('@assets/images/icon_commodity.png'),
    icon_active: require('@assets/images/icon_commodity_actived.png')
}, {
    name: '商品视频',
    key: IMAGE_TYPE.COMMODITY_VIDEO,
    icon: require('@assets/images/icon_commodity_video.png'),
    icon_active: require('@assets/images/icon_commodity_video_actived.png')
}, {
    name: '模特视频',
    key: IMAGE_TYPE.MODEL_VIDEO,
    icon: require('@assets/images/icon_commodity_video.png'),
    icon_active: require('@assets/images/icon_commodity_video_actived.png')
}]

const Sidebar = () => {
    const navigate = useNavigate();
    const { globalHomePageType, config, globalUser } = useContext(AppContext);
    const location = useLocation();
    const [isPersonalCenter, setPersonalCenter] = useState(false)
    useEffect(() => {
        if( location.pathname == '/home/homemain/personalCenter') {
            setPersonalCenter(true)
        } else {
            setPersonalCenter(false)
        }
    }, [location.pathname]);
    const handleSpanClick = (index) => {
        switch (index) {
            case IMAGE_TYPE.MODEL:
                navigate("/home/homemain/model")
                break;
            case IMAGE_TYPE.DRESSFORM:
                navigate("/home/homemain/dressform")
                break;
            case IMAGE_TYPE.CLOTHES:
                navigate("/home/homemain/clothes")
                break;
            case IMAGE_TYPE.COMMODITY:
                navigate("/home/homemain/commodity")
                break;
            case IMAGE_TYPE.COMMODITY_VIDEO:
                navigate("/home/homemain/commodity_video")
                break;
            case IMAGE_TYPE.MODEL_VIDEO:
                navigate("/home/homemain/model_video")
        }
    }

    const menuList = useMemo(() => {
        if (globalHomePageType == HOME_PAGE_TYPE.CLOTHING) {
            return [
                MENU_LIST[0],
                MENU_LIST[1],
                MENU_LIST[4],
            ]
        } else if (globalHomePageType == HOME_PAGE_TYPE.MERCHANDISE) {
            return [
                MENU_LIST[2],
            ]
        } else return []
    }, [globalHomePageType])

    const myProjectAvailable = useMemo(() => {
        return globalHomePageType != HOME_PAGE_TYPE.IMAGE_SERVICE
    }, [globalHomePageType])
    
    const aieditAvailable = useMemo(() => {
        return !Utils.isMobileDevice() && globalHomePageType != HOME_PAGE_TYPE.IMAGE_SERVICE
    }, [globalHomePageType])

    return (
        <div className={styles.Sidebar}>
            <div className={styles.Sidebar_top}>
            {
                menuList.map(menu => (
                    <div
                        key={menu.key}
                        className={`${styles.Sidebar_item} ${(config.sidebarKey == menu.key || (config.sidebarKey == IMAGE_TYPE.DRESSFORM && menu.key == IMAGE_TYPE.MODEL)) && styles.actived}`}
                        onClick={() => handleSpanClick(menu.key)}
                    >
                        <img
                            className={styles.sidebar_icon}
                            src={(config.sidebarKey == menu.key) || (config.sidebarKey == IMAGE_TYPE.DRESSFORM && menu.key == IMAGE_TYPE.MODEL) ? menu.icon_active : menu.icon}
                            alt=""
                        />
                        <p>{menu.name}</p>
                    </div>
                ))
            }
            {
                myProjectAvailable &&
                <div
                    className={`${styles.Sidebar_item} ${config.sidebarKey == 0 && styles.actived}`}
                    onClick={() => navigate("/home/homemain/myProject")}
                >
                    <img 
                        className={styles.sidebar_icon}
                        src={config.sidebarKey == 0?require('@assets/images/icon_projects_actived.png'):require('@assets/images/icon_projects.png')}
                        alt=""
                    />
                    <p>我的项目</p>
                </div>
            }
            {
                aieditAvailable && 
                <div
                    className={`${styles.Sidebar_item} ${config.sidebarKey == 'aiEdit' && styles.actived}`}
                    onClick={() => navigate("/home/homemain/aiEdit")}
                >
                    <img
                        className={styles.sidebar_icon}
                        src={config.sidebarKey == 'aiEdit'? require('@assets/images/icon_aiedit_actived.png'):require('@assets/images/icon_aiedit.png')}
                        alt=""
                    />
                    <p>AI 编辑</p>
                </div>
            }

            </div>
            
            <SidebarTasks />
        </div>
    );
};

export default Sidebar;