import styles from './index.module.scss'
import { Upload, Button, Divider, Input, message, Carousel, Select, Checkbox, Spin } from 'antd';
import React, {useState, useEffect, useRef, useMemo, useContext, useCallback} from 'react';
import API from '@api/api'
import { PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import Utils from '@utils/utils'
import Crop from '@view/compoents/crop/crop';
import Matting from '../components/Matting'
import { IMAGE_TYPE } from "@utils/CONST"
import StrengthSlider from '../components/StrengthSlider.jsx';
import VersionList from '../components/VersionList.jsx';
import PSAIModal from '@PSAIComponents/PSAIModal';
import { AppContext } from '@utils/AppContext';
import * as _ from "underscore";
const { TextArea } = Input;

export default function CustomSceneForCommodity (props) {
    const {isProEnv} = useContext(AppContext)
    const [positivePrompt, setPositivePrompt] = useState(props.positivePrompt)
    const [refImg, setRefImg] = useState(props.bkgUrl || '')
    const initedRef = useRef(false) // 初始化时不应该更改描述场景，避免覆盖掉用户自己填写的场景
    const maxLimit = useRef(2048)
    const minLimit = useRef(500)

    // the refine function version,0 represents no refine,1 rpresents v1, 2 represents v2
    // v1-背景合成 v2-形似风格 v3-拍同款
    const [version, setVersion] = useState((!props.refVersion && props.refVersion != 0) ? 2 : props.refVersion)
    // when v1, ref_strength, default=0.3
    // when v2, ref_prompt_strength, default=0.7
    const [strength, setStrength] = useState((!props.refStrength && props.refStrength != 0) ? 0.7 : props.refStrength)

    const [loading, setLoading] = useState(false)
    const [isModal, setIsModal] = useState(false)
    const [isMattiongOpen, setIsMattiongOpen] = useState(false)
    const [cropParams, setCropParams] = useState({
        height: '',
        width: '',
        src: ''
    })
    const [confirmLoading, setConfirmLoading] = useState(false);
    
    const customListRef = useRef([])
    const [customList, setCustomList] = useState([])
    const getCustomCommodityBackgroundList = () => {
        return API.commodityBackgroundList({
            custom: 1
        }).then(res => {
            if (res.code == 0) {
                customListRef.current = res.data
                setCustomList(res.data)
            } else {
                customListRef.current = []
                setCustomList([])
                message.warning(res.message)
            }
        })
    }
    useEffect(() => {
        getCustomCommodityBackgroundList().then(() => {
            if (refImg) {
                const item = customListRef.current.find(item => item.imageUrl == refImg)
                if (item) {
                    if (version != 2) initedRef.current = true
                    else {
                        savePrompt(item.id, positivePrompt).then(res => {
                            initedRef.current = true
                        })
                    }
                } else {
                    setRefImg("")
                    initedRef.current = true
                }
            } else {
                initedRef.current = true
            }
        })
    }, [])

    const showarr1 = useMemo(() => {
        if(customList.length == 0) return []
        return customList.filter(item => item.attr.version == 1 || !item.attr.version)
    }, [customList])
    const showarr2 = useMemo(() => {
        if(customList.length == 0) return []
        return customList.filter(item => item.attr.version == 2)
    }, [customList])
    const showarr3 = useMemo(() => {
        if(customList.length == 0) return []
        return customList.filter(item => item.attr.version == 3)
    }, [customList])
    const showList = useMemo(() => {
        if(version == 1) {
            return Utils.chunkArray([...showarr1], 9)
        } else if(version == 2) {
            return Utils.chunkArray([...showarr2], 9)
        } else if (version == 3) {
            return Utils.chunkArray([...showarr3], 9)
        }
    }, [showarr1, showarr2, showarr3, version])

    const refImgId = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        return item?.id
    }, [refImg, customList])
    const refImgLocalInfo = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        if (item) {
            return item.attr.objectLocationInfo || null
        } else {
            return null
        }
    }, [refImg, customList])
    const refImgSize = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        if (item) {
            return {
                width: item.attr.width,
                height: item.attr.height
            }
        } else {
            return null
        }
    }, [refImg, customList])
    const refImgMask = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        if (item) {
            return item.attr.mask || null
        } else return null
    }, [refImg, customList])
    const refImgProcessed = useMemo(() => {
        const item = customList.find(item => item.imageUrl == refImg)
        if (item) {
            return item.attr.imgProcessed || null
        } else return null
    }, [refImg, customList])
    useEffect(() => {
        return () => {
            props.onChange({
                definedFlag: 0,
                positivePrompt: '',
                refImg: '',
                refImgMask: null,
                refImgProcessed: "",
                refImgSize: null,
                refImgLocalInfo: null,
                refVersion: 2,
                refStrength: .3,
            })
        }
    }, [])
    useEffect(() => {
        props.onChange({
            definedFlag: 1,
            positivePrompt: positivePrompt,
            refImg: refImg,
            refImgMask: refImgMask,
            refImgProcessed: refImgProcessed,
            refImgSize: refImgSize,
            refImgLocalInfo: refImgLocalInfo,
            refVersion: version,
            refStrength: strength
        })
    }, [positivePrompt, refImg, refImgMask, refImgProcessed, refImgSize, refImgLocalInfo, version, strength])

    const getFileWidthHeight = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = function(e) {
                const img = new window.Image()
                img.onload = function() {
                    const width = img.width
                    const height = img.height
                    resolve({width, height})
                }
                img.src = e.target.result
            }
            reader.readAsDataURL(file)
        })
    }
    const uploadFile = (file) => {
        return Utils.checkImageIslegal(file, 1).then(res => {
            if (!res) return false
            if (!res.isLegal) {
                PSAIModal.confirm({
                    content: '经检测，您上传的内容涉嫌违规，已进行屏蔽处理，人工审核会对图片进行复核。',
                    okText: '重新上传',
                    cancelText: '取消',
                    onOk() {
                        Utils.openFile().then((res) => {
                            if(res) beforeUpload(res)
                        })
                    },
                    onCancel() {},
                })
                return false
            } else {
                const {imageAntispamId, data} = res
                return {
                    url: data,
                    imageAntispamId
                }
            }
        }).catch((err) => {
            message.error(err.message)
        })
    }
    // 上传bkg_url, 测试用
    const beforeUpload = async (file) => {
        try {
            setLoading(true)
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/webp'|| file.type === 'image/avif';
            if (!isJpgOrPng) throw new Error('您只能上传JPG/PNG/WEBP/AVIF格式的文件')
            const resizeRes = await Utils.resizeImageFileMaxByAliyunBy(file, maxLimit.current)
            if (!resizeRes || !resizeRes.success) throw new Error(resizeRes?.message)
            const {width, height} = await getFileWidthHeight(file)
            
            if (version == 3) {
                if (Math.min(width, height) < minLimit.current) {
                    throw new Error('请上传尺寸在500x500以上的参考图片')
                }
                setCropParams({
                    src: resizeRes.base64,
                    width: resizeRes.width,
                    height: resizeRes.height
                })
                setIsMattiongOpen(true)
            } else {
                setIsModal(true)
                setCropParams({
                    src: resizeRes.base64,
                    width: resizeRes.width,
                    height: resizeRes.height
                })
            }
        } catch (err) {
            message.warning(err.message)
        } finally {
            setLoading(false)
            return false
        }
    }

    const [textLoading, setTextLoading] = useState(false)
    const image2Text = (image_path) => {
        setTextLoading(true)
        return API.image2Text({
            image_path: image_path
        }).then(res => {
            if(res.code == 0) {
                return res.data
            } else {
                message.error(res.message)
            }
        }).finally(() => {
            setTextLoading(false)
        })
    }
    
    const handleSelect = (item) => {
        if (refImg == item.imageUrl) {
            setRefImg("")
        } else {
            setRefImg(item.imageUrl)
        }
    }

    // 取消选中清空positivePrompt；
    // 选中后还原prompt或获取caption
    useEffect(() => {
        if (!initedRef.current) return
        if (version == 2) {
            if (!refImgId) setPositivePrompt()
            else {
                const item = customListRef.current.find(item => item.id == refImgId)
                if (item.attr.prompt) {
                    setPositivePrompt(item.attr.prompt)
                } else {
                    const url = item.imageUrl
                    image2Text(url).then(text => {
                        setPositivePrompt(text)
                        savePromptDebounce(refImgId, text)
                    })
                }
            }
        }
    }, [refImgId, version])

    const savePromptDebounce = useCallback(_.debounce((id, prompt) => {
        return API.savePrompt({
            id,
            prompt
        }).then(() => {
            return getCustomCommodityBackgroundList()
        }).catch(err => {
            message.warning(err.message)
        })
    }, 400), [])
    const savePrompt = (id, prompt) => {
        return API.savePrompt({
            id,
            prompt
        }).then(() => {
            return getCustomCommodityBackgroundList()
        }).catch(err => {
            message.warning(err.message)
        })
    }

    const handelDelete = (item, e) => {
        e.stopPropagation()
        PSAIModal.confirm({
            title: "删除参考图",
            content: "请确认是否删除此参考图？",
            onOk() {
                return API.removeCustomMaterial({
                    id: item.id
                }).then(res => {
                    if(res.code == 0) {
                        message.success('删除成功')
                        getCustomCommodityBackgroundList().then(() => {
                            if (refImg == item.imageUrl) setRefImg("")
                        })
                    } else {
                        message.error(res.message)
                    }
                })
            },
            onCancel() {},
        })
    }

    const mattingRef = useRef()
    const mattingOk = async () => {
        setConfirmLoading(true)
        try {
            const mattingResult = await mattingRef.current.getMattingResult()
            if (!mattingResult) throw new Error('图片处理中，请稍后')
            const {refImg, refImgFile, refMask, refImgCropped, width, height, locationInfo} = mattingResult
            const res = await uploadFile(refImgFile)
            if (res) {
                const {url, imageAntispamId} = res
                const res2 = await API.saveCustomMaterial({
                    func: IMAGE_TYPE.COMMODITY,
                    type: "background",
                    image: url,
                    imageAntispamId: imageAntispamId,
                    attr: {
                        width: width,
                        height: height,
                        version: version,
                        mask: refMask,
                        imgProcessed: refImgCropped,
                        objectLocationInfo: locationInfo
                    }
                })
                if (res2.code != 0) throw new Error(res2.message)
                message.success('添加成功')
                const imageUrl = res2.data.imageUrl
                getCustomCommodityBackgroundList().then(() => setRefImg(imageUrl))
    
                setIsMattiongOpen(false)
            } else {
                setIsMattiongOpen(false)
            }
        } catch (err) {
            message.warning(err.message)
        } finally {
            setConfirmLoading(false)
        }
    }

    const cropRef = useRef()
    const cropOk = async () => {
        setConfirmLoading(true)
        try {
            const cropResult = cropRef.current.getCropResult()
            if (Math.min(cropResult.width, cropResult.height) < minLimit.current) throw new Error('请上传尺寸在500x500以上的参考图片')
            let base64 = await Utils.cropImageBase64(cropParams.src, cropResult.x, cropResult.y, cropResult.width, cropResult.height)
            let file = Utils.convertBase64ToFile(base64)
            const width = cropResult.width
            const height = cropResult.height
            const res = await uploadFile(file)
            if (res) {
                const {url, imageAntispamId} = res
                const res2 = await API.saveCustomMaterial({
                    func: IMAGE_TYPE.COMMODITY,
                    type: "background",
                    image: url,
                    imageAntispamId: imageAntispamId,
                    attr: {
                        width: width,
                        height: height,
                        version: version
                    }
                })
                if (res2.code != 0) throw new Error(res2.message)
                message.success('添加成功')
                const imageUrl = res2.data.imageUrl
                getCustomCommodityBackgroundList().then(() => setRefImg(imageUrl))

                setIsModal(false)
            } else {
                setIsModal(false)
            }
        } catch (err) {
            console.error(err)
            message.warning(err.message)
        } finally {
            setConfirmLoading(false)
        }
    }

    const onVersionChange = (version) => {
        const strength = version == 1 ? 0.3 :
                        version == 2 ? 0.7 : 
                        version == 3 ? 0.6 : 0
        setVersion(version)
        setStrength(strength)
        setRefImg("")
        setPositivePrompt()
    }

    const handlePromptChange = (e) => {
        setPositivePrompt(e.target.value)
        if (refImgId) savePromptDebounce(refImgId, e.target.value)
    }

    const versionOptions = [{
        name: '相似风格',
        value: 2,
        isNew: false,
        tipImg: require('@assets/images/guide_ref_img_similarity.png'),
        placement: "rightTop",
        imgStyle: {
            width: 288,
            height: 478,
            marginTop: -32,
        }
    }, {
        name: '背景合成',
        value: 1,
        tipImg: require('@assets/images/guide_ref_img_samekind.png'),
        imgStyle: {   
            width: 267+39,
            height: 155,
            marginLeft: -39,
        }
    }, {
        name: '拍同款',
        value: 3,
        tipImg: require('@assets/images/guide_ref_img_same.png'),
        imgStyle: {   
            width: 267+39,
            height: 155,
            marginLeft: -39,
        }
    }]
    return (
        <div className={styles.Container} >
            <VersionList version={version} options={versionOptions} onChange={onVersionChange} />
            {
                version == 2 &&
                <>
                <div className={styles.Prompt}>
                    <p className={styles.PromptTitle}>描述场景</p>
                    <Spin spinning={textLoading}>
                    <TextArea
                        className={styles.PromptInput}
                        rows={4}
                        placeholder="请在此描述您期望的展示场景，例如：高级感展台，黑色背景，光影感，质感"
                        value={positivePrompt}
                        variant="borderless"
                        onChange={handlePromptChange}
                    />
                    </Spin>
                </div>
                <p className={styles.MaterialTtile}>上传参考图</p>
                </>
            }
            <div className={styles.MaterialTools}>
                <Upload
                    accept="image/jpeg,image/png,image/webp"
                    name="avatar"
                    listType="picture-card"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    className={styles.MaterialUploader}
                >
                    <div className={styles.MaterialUploaderBox}>
                        {loading ? <LoadingOutlined /> : <PlusOutlined />}
                    </div>
                </Upload>
                <div className={styles.MaterialStrength}>
                    {version == 1 && <p style={{marginBottom: '15px'}}>请上传不含商品的背景图</p>}
                    <StrengthSlider
                        leftTips="更加自由"
                        rightTips="非常相似"
                        disabled={!refImg}
                        title="参考图相似度"
                        reverse={version == 1}
                        value={strength}
                        onChange={setStrength}
                        showTooltip={!isProEnv}
                        step={0.1}
                    />
                </div>
            </div>
            <div className={styles.MaterialList}>
                <Carousel dots={showList.length > 1 ? true: false}>
                {
                    showList.map((item, index) => (
                        <div key={index}>
                            <div className={styles.MaterialGroup}>
                            {
                                item.map(item2 => (
                                    <div
                                        key={item2.id}
                                        className={`${styles.materialBox} ${refImg == item2.imageUrl && styles.selectedImg}`}
                                        onClick={() => handleSelect(item2)}
                                    >
                                        <img src={Utils.getImgUrlWithWebp(item2.imageUrl, true)} />
                                        <i className={styles.del_btn} onClick={(e) => handelDelete(item2, e)}></i>
                                    </div>
                                ))
                            }
                            </div>
                        </div>
                    ))
                }
                </Carousel>
            </div>
            <PSAIModal
                title='编辑参考图尺寸'
                open={isModal}
                maskClosable={false}
                closable={false}
                maxWidth={548}
                onCancel={() => setIsModal(false)}
                confirmLoading={confirmLoading}
                onOk={cropOk}
            >
                <div style={{padding: '20px'}}>
                    <Crop ref={cropRef} cropParams={cropParams} />
                </div>
            </PSAIModal>
            <PSAIModal
                title='上传参考图'
                open={isMattiongOpen}
                maskClosable={false}
                closable={false}
                maxWidth={548}
                onCancel={() => setIsMattiongOpen(false)}
                confirmLoading={confirmLoading}
                onOk={mattingOk}
            >
                <div style={{padding: '20px'}}>
                    <Matting ref={mattingRef} cropParams={cropParams} />
                </div>
            </PSAIModal>
        </div>
    )
}