import styles from './index.module.scss'
import { useContext, useState, useMemo, useEffect } from 'react'
import { Modal, Segmented, message, ConfigProvider } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { CLOTH_TYPE, IMAGE_TYPE, UPLOAD_SOURCE } from "@utils/CONST";

const SegmentedCom = () => {
    const navigate = useNavigate();

    const [segmentedVal, setSegmentedVal] = useState('model')

    // 根据当前路由初始化 segmentedVal,否则父组件会刷新导致问题
    useEffect(() => {
        if (location.pathname.includes("model")) {
            setSegmentedVal(IMAGE_TYPE.MODEL);
        } else if (location.pathname.includes("dressform")) {
            setSegmentedVal(IMAGE_TYPE.DRESSFORM);
        }
    }, [location.pathname]);

    const segmentedChange = (value) => {
        setSegmentedVal(value)
        if (value == IMAGE_TYPE.MODEL) {
            navigate("/home/homemain/model")
        } else {
            navigate("/home/homemain/dressform")
        }
    }

    return (
        <ConfigProvider
            theme={{
                components: {
                    Segmented: {
                        trackBg: 'rgba(227, 227, 246, 1)',
                    },
                },
            }}
        >
            <div className={styles.segmented}  style={{ width: '220px', margin: '0 auto' }}>
                <Segmented
                    options={[
                        { label: '真人图', value: IMAGE_TYPE.MODEL },
                        { label: '人台图', value: IMAGE_TYPE.DRESSFORM },
                    ]}
                    block
                    value={segmentedVal}
                    onChange={segmentedChange}
                />
            </div>
        </ConfigProvider>
    )
}

export default SegmentedCom