import { createContext, useEffect, useState, useRef, useContext, useMemo } from "react";
import { CLOTH_TYPE, IMAGE_TYPE } from "@utils/CONST";
import { AppContext } from '@utils/AppContext';
import eventBus from '@utils/eventBus';

export const GenerateContext = createContext()

export const GenerateContextProvider = (props) => {
    const { isProEnv } = useContext(AppContext)
    const [commodityFilter, setCommodityFilter] = useState({})
    const [modelFilter, setModelFilter] = useState({})

    const isMounted = useRef(true) // 判断组件是否已销毁

    // 解决初始化时childRef未及时初始化的问题
    // 使用init避免多次childRef.current change触发事件
    const [initialized, setInitialized] = useState(false)

    const [imageType] = useState(props.imageType) // model/dressform/clothes...
    const [clothType, setClothType] = useState(props.imageType == IMAGE_TYPE.CLOTHES ? CLOTH_TYPE.SUIT : CLOTH_TYPE.SKIRT) // 来自服装类型的选择，仅用于AI试衣的区分
    
    // 原始输入图
    const [inputImage, setInputImage] = useState() // 真人/人台/AI试衣-套装
    const [inputImageTop, setInputImageTop] = useState() // AI试衣-上装
    const [inputImageBottom, setInputImageBottom] = useState() // AI试衣-下装

    const dragScaleRef = useRef([]) // 商品+softedge布局等
    const [dragScale, setDragScale] = useState([]) // 商品+softedge布局等

    const [ltX, setLtX] = useState(0)
    const ltXRef = useRef(0) // 模特mask的偏移量
    const [ltY, setLtY] = useState(0)
    const ltYRef = useRef(0) // 模特mask的偏移量
    const [scaleRate, setScaleRate] = useState(1)
    const scaleRateRef = useRef(1) // 模特mask的缩放
    const [coordDrag, setCoordDrag] = useState() // 模特背景的位置缩放
    const coordDragRef = useRef()
    const [imgClipSize, setImgClipSize] = useState() // 模特图像剪裁后的尺寸

    // 模特/商品的背景img（由于商品拍同款/真人预设风格）
    const [bgImage, setBgImage] = useState()
    
    // 算法相关参数
    const [aiInteractiveNpy, setAiInteractiveNpy] = useState() // npy-套装
    const [aiInteractiveNpy1, setAiInteractiveNpy1] = useState() // npy-上身
    const [aiInteractiveNpy2, setAiInteractiveNpy2] = useState() // npy-下身
    const maskAlgoIdRef = useRef() // mask算法id-套装
    const maskAlgoId1Ref = useRef() // mask算法id-上身
    const maskAlgoId2Ref = useRef() // mask算法id-下身
    const [segmentResult, setSegmentResult] = useState([]) // 分割结果列表-套装
    const [segmentResult1, setSegmentResult1] = useState([]) // 分割结果列表-上身
    const [segmentResult2, setSegmentResult2] = useState([]) // 分割结果列表-下身
    
    // 选择参数
    const [modelStyle, setModelStyle] = useState('') // 选择的模特
    const [poseStyleList, setPoseStyleList] = useState([]) // 选择的姿势列表
    const [customPoseStyleList, setCustomPoseStyleList] = useState([]) // 选择的自定义姿势列表
    const [bgStyle, setBgStyle] = useState() // 选择的场景

    const [outputMaxSize, setOutputMaxSize] = useState(0) // 决定商品生成参数upscale_enable是1/0
    const [projectId, setProjectId] = useState() // 生成的项目id
    const [rightProjectId, setRightProjectId] = useState() // 右侧的项目id
    const [projectName, setProjectName] = useState('未命名')
    const [taskId, setTaskId] = useState() // 任务id，暂时没什么用

    const [createNum, setCreateNum] = useState(4) // 生成数量
    
    const [subBgId, setSubBgId] = useState(-1) // 场景内部子index
    
    const [faceTagText, setFaceTagText] = useState('默认') // 特征属性：表情
    const [hairTagText, setHairTagText] = useState('默认') // 特征属性：发型

    // 商品自定义尺寸
    const imgWidthRef = useRef(1024)
    const [imgWidth, setImgWidth] = useState(1024)
    const imgHeightRef = useRef(1024)
    const [imgHeight, setImgHeight] = useState(1024)
    const imgRatioRef = useRef(imageType == IMAGE_TYPE.MODEL || imageType == IMAGE_TYPE.DRESSFORM ? 'custom' : '1:1')
    const [imgRatio, setImgRatio] = useState(imageType == IMAGE_TYPE.MODEL || imageType == IMAGE_TYPE.DRESSFORM ? 'custom' : '1:1')

    // 自定义场景字段
    const [definedFlag, setDefinedFlag] = useState(0)
    const [positivePrompt, setPositivePrompt] = useState("")
    const [refImg, setRefImg] = useState("") // 自定义场景参考图
    const [refImgSize, setRefImgSize] = useState() // 自定义场景参考图尺寸
    const [refImgMask, setRefImgMask] = useState("") // 自定义场景参考图mask
    const [refImgErased, setRefImgErased] = useState("") // 自定义场景参考图擦除后的图
    const [refImgLocalInfo, setRefImgLocalInfo] = useState() // 记录商品替换原背景中物体的位置信息
    // 商品：v1-背景合成(0.3) v2-形似风格(0.7) v3-拍同款(0.6)；
    // 模特图：v1-相似风格(0.5) v2-场景裂变(0.2)
    const [refVersion, setRefVersion] = useState()
    const [refStrength, setRefStrength] = useState()    
    const defaultPositivePrompt = useRef("Tranquil countryside, (farmhouses), wide open spaces, unspoiled landscapes")
    const defaultNegativePrompt = useRef("(3d, render, cgi, doll, painting, fake, cartoon, photoshop, 3d modeling:1.2), (retouched, airbrushed:1.2),sketches, (worst quality:2), (low quality:2), (normal quality:2), lowres,((monochrome)), ((grayscale)), skin spots, acnes, skin blemishes,bad anatomy,DeepNegative,(fat:1.2),facing away, looking away,tilted head, {Multiple people},bad hands,jpegartifacts, username,blurry,bad feet,cropped,poorly drawn hands,poorly drawn face,mutation,deformed,jpeg artifacts,signature,watermark,extra fingers,fewer digits,extra limbs,extra arms,extra legs,malformed limbs,fused fingers,too many fingers,long neck,cross-eyed,mutated hands,polar lowres,bad body,bad proportions,gross proportions,text,error,missing fingers,missing arms,missing legs,extra digit, extra foot")
    const defaultBkgUrl = useRef("https://photostudio-ai.oss-cn-hangzhou.aliyuncs.com/photostudio-web/admin/default/bkg_url.png")
    
    // 构图优化推荐区域和缓存区域
    const originCropParamsRef = useRef()
    const [originCropParams, setOriginCropParams] = useState()
    const modelScaleRef = useRef()
    const [modelScale, setModelScale] = useState()

    // 清除taskCache
    const disableCacheRef = useRef(false)

    // 图片审核用的id
    const [imageAntispamId, setImageAntispamId] = useState()

    // 用来生成视频的图片
    const [img4Video, setImg4Video] = useState()
    const [videoResolution, setVideoResolution] = useState(720)

    // 模特视频
    const [modelVideo, setModelVideo] = useState()
    const [modelVideoFrontPic, setModelVideoFrontPic] = useState() // 正面图
    const [modelVideoBackPic, setModelVideoBackPic] = useState() // 背身图

    const [clothLoadingFlag, setClothLoadingFlag] = useState(false) // 分开上传衣服图套装时，对生成按钮做保护
    const [clothLoadingFlag2, setClothLoadingFlag2] = useState(false)

    // 上传文件loading
    const [globaluploadLoading, setGlobaluploadLoading] = useState(false)

    // 记录预设场景/自定义场景 切换
    const [isCustomScene, setIsCustomScene] = useState(false) // 自定义场景(defined_flag==1)

    const [isSimilarStyle, setIsSimilarStyle] = useState(false) // 相似风格
    const [similarStyleScale, setSimilarStyleScale] = useState(.5) // 相似风格强度

    const [isSameStyle, setIsSameStyle] = useState(false) // 拍同款
    const [sameStyleScale, setSameStyleScale] = useState(.5) // 拍同款强度

    // 仅重绘皮肤区域，默认 0 重绘背景, 3 change slightly 场景裂变, 2 no change 保持原图场景
    const [isSceneFracturing, setIsSceneFracturing] = useState(false) // 场景裂变 true->setKeepBkg(1)
    const [dpScale, setDpScale] = useState(.5) // 场景裂变强度

    // tryon 保持模特不变/保持背景不变
    const [keepPose, setKeepPose] = useState((props.imageType == IMAGE_TYPE.CLOTHES || imageType == IMAGE_TYPE.MODEL))
    const [keepBkg, setKeepBkg] = useState(props.imageType == IMAGE_TYPE.CLOTHES) 

    const [softEdgeList, setSoftEdgeList] = useState()
    const [modelDate, setModelList] = useState()
    const [poseDate, setPoselList] = useState() // 预设姿势素材
    const [customPoseDate, setCustomPoseList] = useState() // 自定义姿势素材
    const [scenelDate, setScenelList] = useState()

    // mask支持移动
    const maskMoveable = useMemo(() => {
        if (imageType == IMAGE_TYPE.MODEL || imageType == IMAGE_TYPE.DRESSFORM) {
            if (definedFlag == 1) {
                if (isSceneFracturing) return false
                else if (isSameStyle) return false
                else if (isSimilarStyle) return true
            } else {
                return bgStyle != '00_keep'
            }
        } else if (imageType == IMAGE_TYPE.COMMODITY) {
            return true
        } else {
            return false
        }
    }, [imageType, bgStyle, isSceneFracturing, isSimilarStyle, isSameStyle, definedFlag])

    const taskCacheHandler = () => {
        sessionStorage.setItem(`taskCache_${imageType}`, JSON.stringify({
            inputImage,
            inputImageTop,
            inputImageBottom,
            
            commodityFilter: {...commodityFilter},
            modelFilter: {...modelFilter},
            dragScale: JSON.stringify(dragScaleRef.current),

            lt_x: ltXRef.current,
            lt_y: ltYRef.current,
            scaleRate: scaleRateRef.current,

            coordDrag: coordDragRef.current,

            bgImage: bgImage,

            maskAlgoId: maskAlgoIdRef.current,
            maskAlgoId1: maskAlgoId1Ref.current,
            maskAlgoId2: maskAlgoId2Ref.current,

            segmentResult: segmentResult,
            segmentResult1: segmentResult1,
            segmentResult2: segmentResult2,

            aiInteractiveNpy: aiInteractiveNpy,
            aiInteractiveNpy1: aiInteractiveNpy1,
            aiInteractiveNpy2: aiInteractiveNpy2,

            modelStyle: modelStyle,
            poseStyleList: poseStyleList,
            customPoseStyleList: customPoseStyleList,
            bgStyle: bgStyle,

            projectId: projectId,
            rightProjectId: rightProjectId,
            projectName: projectName,
            clothType, clothType,
            createNum: createNum,

            imgRatio: imgRatioRef.current,
            imgWidth: imgWidthRef.current,
            imgHeight: imgHeightRef.current,


            definedFlag: definedFlag,
            positivePrompt: positivePrompt,
            refImg: refImg,
            refImgSize: refImgSize,
            refImgMask: refImgMask,
            refImgErased: refImgErased,
            refVersion: refVersion,
            refStrength: refStrength,
            refImgLocalInfo: refImgLocalInfo,

            faceTagText: faceTagText,
            hairTagText: hairTagText,

            outputMaxSize: outputMaxSize,
            subBgId: subBgId,

            originCropParams: originCropParamsRef.current,
            modelScale: modelScaleRef.current,

            imageAntispamId: imageAntispamId,

            modelVideoFrontPic: modelVideoFrontPic,
            modelVideoBackPic: modelVideoBackPic,
            img4Video: img4Video,

            isCustomScene: isCustomScene,

            isSimilarStyle: isSimilarStyle,
            similarStyleScale: similarStyleScale,

            isSameStyle: isSameStyle,
            sameStyleScale: sameStyleScale,

            isSceneFracturing: isSceneFracturing,            
            dpScale: dpScale,

            keepPose: keepPose,
            keepBkg: keepBkg,
        }))
    }
    const taskRestoreHandler = () => {
        const taskCacheStr = sessionStorage.getItem(`taskCache_${imageType}`)
        if (!taskCacheStr) return
        const taskCache = JSON.parse(taskCacheStr)
        setCommodityFilter(taskCache?.commodityFilter ? {...taskCache.commodityFilter} : {})
        setModelFilter(taskCache?.modelFilter ? {...taskCache.modelFilter} : {})

        setInputImage(taskCache.inputImage)
        setInputImageTop(taskCache.inputImageTop)
        setInputImageBottom(taskCache.inputImageBottom)

        dragScaleRef.current = taskCache.dragScale ? JSON.parse(taskCache.dragScale) : []
        setDragScale(dragScaleRef.current)

        ltXRef.current = taskCache.lt_x || 0
        setLtX(ltXRef.current)
        ltYRef.current = taskCache.lt_y || 0
        setLtY(ltYRef.current)
        scaleRateRef.current = taskCache.scaleRate || 1
        setScaleRate(scaleRateRef.current)

        coordDragRef.current = taskCache.coordDrag
        setCoordDrag(coordDragRef.current)

        setBgImage(taskCache.bgImage)
        
        setAiInteractiveNpy(taskCache.aiInteractiveNpy)
        setAiInteractiveNpy1(taskCache.aiInteractiveNpy1)
        setAiInteractiveNpy2(taskCache.aiInteractiveNpy2)

        maskAlgoIdRef.current = taskCache.maskAlgoId
        maskAlgoId1Ref.current = taskCache.maskAlgoId1
        maskAlgoId2Ref.current = taskCache.maskAlgoId2

        setSegmentResult(taskCache.segmentResult || [])
        setSegmentResult1(taskCache.segmentResult1 || [])
        setSegmentResult2(taskCache.segmentResult2 || [])

        setModelStyle(taskCache.modelStyle || '')
        setPoseStyleList(taskCache.poseStyleList || [])
        setCustomPoseStyleList(taskCache.customPoseStyleList || [])
        setBgStyle(taskCache.bgStyle || '')

        setOutputMaxSize(taskCache.outputMaxSize || 0)
        setProjectId(taskCache.projectId || null)
        setRightProjectId(taskCache.rightProjectId || null)
        setClothType(taskCache.clothType || 2)
        setCreateNum(taskCache.createNum || 4)

        if (imageType == IMAGE_TYPE.MODEL) {
            // 兼容模特生成的旧数据
            if (taskCache.imgRatio == 'recommend') imgRatioRef.current = 'custom' // 原来的推荐删除，改为了自定义
            else if (taskCache.imgRatio == 'original') imgRatioRef.current = 'custom' // 原来的原始比例删除，改为了自定义
            else imgRatioRef.current = taskCache.imgRatio || '1:1'
            setImgRatio(imgRatioRef.current)
        } else {
            imgRatioRef.current = taskCache.imgRatio || '1:1'
            setImgRatio(imgRatioRef.current)
        }
        imgWidthRef.current = taskCache.imgWidth || 1024
        setImgWidth(imgWidthRef.current)
        imgHeightRef.current = taskCache.imgHeight || 1024
        setImgHeight(imgHeightRef.current)
        setSubBgId(taskCache.subBgId === 0 ? taskCache.subBgId : (taskCache.subBgId || -1))
        setDefinedFlag(taskCache.definedFlag || 0)
        setPositivePrompt(taskCache.positivePrompt || '')
        setRefImg(taskCache.refImg || '')
        setRefImgSize(taskCache.refImgSize)
        setRefImgMask(taskCache.refImgMask || '')
        setRefImgErased(taskCache.setRefImgErased || '')
        setRefImgLocalInfo(taskCache.refImgLocalInfo)
        setRefVersion(taskCache.refVersion)
        setRefStrength(taskCache.refStrength || 0)
        setHairTagText(taskCache.hairTagText || '默认')
        setFaceTagText(taskCache.faceTagText || '默认')

        originCropParamsRef.current = taskCache.originCropParams
        setOriginCropParams(originCropParamsRef.current)
        modelScaleRef.current = taskCache.modelScale
        setModelScale(modelScaleRef.current)

        setImageAntispamId(taskCache.imageAntispamId)

        setModelVideoFrontPic(taskCache.modelVideoFrontPic)
        setModelVideoBackPic(taskCache.modelVideoBackPic)
        setImg4Video(taskCache.img4Video)

        setIsCustomScene(taskCache.isCustomScene || false)

        setIsSimilarStyle(taskCache.isSimilarStyle || false)
        setSimilarStyleScale(taskCache.similarStyleScale == 0 ? taskCache.similarStyleScale : (taskCache.similarStyleScale || 0.5))

        setIsSameStyle(taskCache.isSameStyle || false)
        setSameStyleScale(taskCache.sameStyleScale == 0 ? taskCache.sameStyleScale : (taskCache.sameStyleScale || 0.5))

        setIsSceneFracturing(taskCache.isSceneFracturing || false)
        setDpScale(taskCache.dpScale == 0 ? taskCache.dpScale : (taskCache.dpScale || 0.5))
    
        setKeepPose(taskCache.keepPose || false)
        setKeepBkg(taskCache.keepBkg || false)

        return taskCache
    }

    const tourTimerRef = useRef()
    useEffect(() => {
        return () => {
            isMounted.current = false
            clearTimeout(tourTimerRef.current)
            eventBus.emit('TOUR_PAUSE')
        }
    }, [])
    
    useEffect(() => {
        if (initialized) {
            if (!disableCacheRef.current) taskCacheHandler()
        } else {
            // 尝试恢复task
            const taskCache = taskRestoreHandler()
            setInitialized(true)
            // tourflow
            tourTimerRef.current = tourflow(taskCache)
        }
    })

    const tourflow = (cache) => {
        let steps = []
        if (imageType == IMAGE_TYPE.MODEL) {
            steps = [{
                title: '欢迎使用真人图模块',
                description: '请先上传一张模特图或选择示例图',
                target: () => document.getElementById('tour_target_upload')
            }, {
                title: ' ',
                description: '选择一个模特',
                target: () => document.getElementById('tour_target_model')
            }, {
                title: ' ',
                description: '选择一个预设场景',
                target: () => document.getElementById('tour_target_scene')
            }, {
                title: ' ',
                description: '点击生成，期待成片吧！',
                target: () => document.getElementById('tour_target_create')
            }]
        } else if (imageType == IMAGE_TYPE.DRESSFORM) {
            steps = [{
                title: '欢迎使用人台图模块',
                description: '请先上传一张人台图或选择示例图',
                target: () => document.getElementById('tour_target_upload')
            }, {
                title: ' ',
                description: '选择一个模特',
                target: () => document.getElementById('tour_target_model')
            }, {
                title: ' ',
                description: '选择一个预设场景',
                target: () => document.getElementById('tour_target_scene')
            }, {
                title: ' ',
                description: '点击生成，期待成片吧！',
                target: () => document.getElementById('tour_target_create')
            }]
        } else if (imageType == IMAGE_TYPE.CLOTHES) {
            steps = [{
                title: '欢迎使用AI试衣模块',
                description: '请先上传一张衣服图或选择示例图',
                target: () => document.getElementById('tour_target_upload')
            }, {
                title: ' ',
                description: '选择一个模特',
                target: () => document.getElementById('tour_target_pose')
            }, {
                title: ' ',
                description: '选择一个容貌',
                target: () => document.getElementById('tour_target_model')
            }, {
                title: ' ',
                description: '选择一个预设场景',
                target: () => document.getElementById('tour_target_scene')
            }, {
                title: ' ',
                description: '点击生成，期待成片吧！',
                target: () => document.getElementById('tour_target_create')
            }]
        } else if (imageType == IMAGE_TYPE.COMMODITY) {
            steps = [{
                title: '欢迎使用商品图模块',
                description: '请先上传一张商品图或选择示例图',
                target: () => document.getElementById('tour_target_upload')
            }, {
                title: ' ',
                description: '选择一个预设场景',
                target: () => document.getElementById('tour_target_scene')
            }, {
                title: ' ',
                description: '点击生成，期待成片吧！',
                target: () => document.getElementById('tour_target_create')
            }]
        } else if (imageType == IMAGE_TYPE.MODEL_VIDEO) {
            steps = [{
                title: '欢迎使用模特视频模块',
                description: '请先上传一张模特图或选择示例图',
                target: () => document.getElementById('tour_target_upload')
            }, {
                title: ' ',
                description: '选择一个动作',
                target: () => document.getElementById('tour_target_action')
            }, {
                title: ' ',
                description: '点击生成，期待成片吧！',
                target: () => document.getElementById('tour_target_create')
            }]
        }
        
        if (steps.length == 0) return
        const stepLength = steps.length
        const key = `tour_${imageType}`
        const current = Number(localStorage.getItem(key) || 0)
        const toured = localStorage.getItem('toured')
        eventBus.emit('TOUR_INIT', steps, key)
        if (current >= stepLength) return
        if (toured) return
        return setTimeout(() => {
            if (
                (cache?.segmentResult && cache.segmentResult.length > 0) ||
                (cache?.segmentResult1 && cache.segmentResult1.length > 0) ||
                (cache?.segmentResult2 && cache.segmentResult2.length > 0) ||
                !!cache?.modelVideoFrontPic
            ) {
                eventBus.emit('TOUR_NEXT')
            } else {
                eventBus.emit('TOUR_PLAY')
            }
        }, 1000)
    }

    useEffect(() => {
        return () => {
            if (!isMounted.current) {
                if (!disableCacheRef.current) taskCacheHandler()
            }
        }
    })

    return (
        <GenerateContext.Provider value={{
            initialized, setInitialized, // taskcache初始化完成
            imageType, // model/dressform/clothes...
            inputImage, setInputImage, // 原始输入图： 真人/人台/AI试衣-套装
            inputImageTop, setInputImageTop, // 原始输入图：衣服图-上装
            inputImageBottom, setInputImageBottom, // 原始输入图：衣服图-下装
            dragScaleRef, dragScale, setDragScale, // 商品+softedge布局等
            ltXRef, ltX, setLtX, // 模特mask的偏移量
            ltYRef, ltY, setLtY, // 模特mask的偏移量
            scaleRateRef, scaleRate, setScaleRate, // 模特mask的缩放
            coordDragRef, coordDrag, setCoordDrag, // 模特背景的位置缩放
            imgClipSize, setImgClipSize, // 模特图像剪裁后的尺寸
            bgImage, setBgImage, // 模特/商品的背景img（由于商品拍同款/真人预设风格）
            aiInteractiveNpy, setAiInteractiveNpy, // npy-套装
            aiInteractiveNpy1, setAiInteractiveNpy1, // npy-上身
            aiInteractiveNpy2, setAiInteractiveNpy2, // npy-下身
            maskAlgoIdRef, // mask算法id-套装
            maskAlgoId1Ref, // mask算法id-上身
            maskAlgoId2Ref, // mask算法id-下身
            segmentResult, setSegmentResult, // 分割结果列表-套装
            segmentResult1, setSegmentResult1, // 分割结果列表-上身
            segmentResult2, setSegmentResult2, // 分割结果列表-下身
            modelStyle, setModelStyle, // 选择的模特
            poseStyleList, setPoseStyleList, // 选择的姿势列表
            customPoseStyleList, setCustomPoseStyleList, // 选择的自定义姿势列表
            bgStyle, setBgStyle, // 选择的场景
            outputMaxSize, setOutputMaxSize, // 决定商品生成参数upscale_enable是1/0
            projectId, setProjectId, // 生成的项目id
            rightProjectId, setRightProjectId, // 右侧的项目id
            projectName, setProjectName, // 生成的项目名
            createNum, setCreateNum, // 生成数量
            clothType, setClothType, // 来自服装类型的选择，仅用于衣服图的区分
            subBgId, setSubBgId, // 场景内部子index，目前只能为-1
            faceTagText, setFaceTagText, // 特征属性：表情
            hairTagText, setHairTagText, // 特征属性：发型
            // 商品自定义尺寸
            imgWidthRef, imgWidth, setImgWidth,
            imgHeightRef, imgHeight, setImgHeight,
            imgRatioRef, imgRatio, setImgRatio,
            // 自定义场景字段
            definedFlag, setDefinedFlag,
            positivePrompt, setPositivePrompt,
            refImg, setRefImg,
            refImgSize, setRefImgSize,
            refImgMask, setRefImgMask,
            refImgErased, setRefImgErased,
            refImgLocalInfo, setRefImgLocalInfo,
            refVersion, setRefVersion,
            refStrength, setRefStrength,
            defaultPositivePrompt,
            defaultNegativePrompt,
            defaultBkgUrl,
            originCropParamsRef, // 构图优化推荐区域
            setOriginCropParams,
            modelScaleRef, // 构图优化缓存区域
            setModelScale,
            // 清除taskCache
            disableCacheRef,
            imageAntispamId, setImageAntispamId, // 图片审核用的id
            img4Video, setImg4Video, // 用来生成视频的图片
            videoResolution, setVideoResolution, // 视频分辨率
            modelVideo, setModelVideo,
            modelVideoFrontPic, setModelVideoFrontPic,
            modelVideoBackPic, setModelVideoBackPic,
            clothLoadingFlag, setClothLoadingFlag,
            clothLoadingFlag2, setClothLoadingFlag2,
            globaluploadLoading, setGlobaluploadLoading,
            isCustomScene, setIsCustomScene,
            isSimilarStyle, setIsSimilarStyle,
            similarStyleScale, setSimilarStyleScale,
            isSameStyle, setIsSameStyle,
            sameStyleScale, setSameStyleScale,
            isSceneFracturing, setIsSceneFracturing,
            dpScale, setDpScale,
            keepPose, setKeepPose, // tryon 保持模特不变
            keepBkg, setKeepBkg, // tryon 保持场景不变
            commodityFilter, setCommodityFilter,
            modelFilter, setModelFilter,
            softEdgeList, setSoftEdgeList,
            modelDate, setModelList,
            poseDate, setPoselList,
            customPoseDate, setCustomPoseList,
            scenelDate, setScenelList,
            maskMoveable,
        }}>
          {props.children}
        </GenerateContext.Provider>
      );
}